// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JvlnysTV{padding-bottom:max(1.13rem,min(1.67vw,2rem));padding-top:max(1.13rem,min(1.67vw,2rem));position:relative}.Q\\+MqFc42{background:#05063a}.KSSIEgv2{align-items:center;display:flex;gap:max(1.69rem,min(2.5vw,3rem));justify-content:space-between}@media(max-width:1023.98px){.QtrgjzOK{flex-direction:row-reverse;gap:max(.7rem,min(1.04vw,1.25rem))}}._0\\+\\+r9cK7{display:inline-flex;height:max(2.31rem,min(4.84vw,5.81rem));width:max(10rem,min(14.32vw,17.19rem))}.nnhWajnL .header-btn,.TNh2G3CG .header-btn{align-items:center;background:var(--color-primary);border-radius:.5em;color:#fff;display:flex;font-size:max(.75rem,min(.94vw,1.13rem));font-weight:700;gap:.5em;padding:.84em 1.5em}@media(hover:hover){.nnhWajnL .header-btn:hover,.TNh2G3CG .header-btn:hover{background:var(--color-primary-hover)}}@media(hover:none){.nnhWajnL .header-btn:active,.TNh2G3CG .header-btn:active{background:var(--color-primary-hover)}}@media(max-width:1023.98px){.nnhWajnL .header-btn,.TNh2G3CG .header-btn{padding:.8em}.nnhWajnL .header-btn>span,.TNh2G3CG .header-btn>span{display:none}}.nnhWajnL .header-btn-icon,.TNh2G3CG .header-btn-icon{--size:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "JvlnysTV",
	"containerBg": "Q+MqFc42",
	"content": "KSSIEgv2",
	"contentEnd": "QtrgjzOK",
	"logo": "_0++r9cK7",
	"actions": "nnhWajnL",
	"menu": "TNh2G3CG"
};
module.exports = ___CSS_LOADER_EXPORT___;
