// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vo1wGoMN{display:flex;flex-direction:column;min-height:100vh}.veVpgJ03{background:#05063a;color:#b8b8b8}.r9zLUfhH{overflow:hidden}.V2I-u8Cp{flex-grow:1;z-index:1}.V2I-u8Cp,.YCqzj97X{display:flex;flex-direction:column}.YCqzj97X{position:relative}.RKExnlqc{max-width:none;padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "Vo1wGoMN",
	"containerDarkBg": "veVpgJ03",
	"containerOverflowHidden": "r9zLUfhH",
	"content": "V2I-u8Cp",
	"inner": "YCqzj97X",
	"innerFullWidth": "RKExnlqc"
};
module.exports = ___CSS_LOADER_EXPORT___;
