
import menuMixin from "@/mixins/menu"

export default menuMixin.extend({
  data: () => ({
    isMenuOpen: false,
  }),

  watch: {
    $route: {
      handler () { this.isMenuOpen = false },
      deep: true,
    },
  },
})
