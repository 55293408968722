// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g3d0Y2dD{align-items:center;-moz-column-gap:17%;column-gap:17%;display:flex}.g3d0Y2dD svg{flex-grow:1;flex-shrink:0;height:100%;width:37%}.g3d0Y2dD img{flex-grow:1;flex-shrink:0;height:45%;-o-object-fit:contain;object-fit:contain;-o-object-position:right;object-position:right;width:46%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "g3d0Y2dD"
};
module.exports = ___CSS_LOADER_EXPORT___;
