// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X8NKf6nI{flex-shrink:0}.tSSplXc3{-o-object-fit:cover;object-fit:cover;-o-object-position:top;object-position:top}.tSSplXc3,.sgXTQcGx{height:100%;width:100%}.sgXTQcGx{align-items:center;display:flex;justify-content:center;-webkit-user-select:none;-moz-user-select:none;user-select:none}.sgXTQcGx>span{color:#000;font-weight:600;opacity:.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "X8NKf6nI",
	"img": "tSSplXc3",
	"initials": "sgXTQcGx"
};
module.exports = ___CSS_LOADER_EXPORT___;
