// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--color-background:var(--color-gray-1);--color-body:var(--color-bg);--color-text:var(--color-fg);--color-inactive:var(--color-gray-3);--color-border:var(--color-gray-3);--color-text-secondary:var(--color-gray-4);--color-success:#92f5a8;--color-warning:#ffc568;--color-error:#eb5757;--color-danger:var(--color-error);--border-radius:0.25rem;--shadow:0 0.25rem 2.94rem rgba(var(--color-shadow-rgb),0.12);--unit-step:0.25rem;--spacing-default:var(--unit-step);--spacing-xs:calc(var(--unit-step)*2);--spacing-s:calc(var(--unit-step)*3);--spacing-sm:calc(var(--unit-step)*4);--spacing-md:calc(var(--unit-step)*6);--spacing-lg:calc(var(--unit-step)*8);--spacing-xl:calc(var(--unit-step)*9);--spacing-xxl:calc(var(--unit-step)*11);--gap:var(--spacing-lg);--header-height:9.81rem;--content-width:82rem;--aside-width:24.38rem;--banner-height:0.01px;--timeline-gap:3.13rem}@media(max-width:1023.98px){:root{--banner-height:5.5rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color-primary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98"
};
module.exports = ___CSS_LOADER_EXPORT___;
