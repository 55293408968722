// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MnmZ94hS,.giGxK\\+wI{align-items:center;display:flex;font-size:max(.75rem,min(.83vw,1rem));gap:1em}@media(hover:hover){.MnmZ94hS:hover .anCenz4x,.giGxK\\+wI:hover .anCenz4x{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.MnmZ94hS:active .anCenz4x,.giGxK\\+wI:active .anCenz4x{-webkit-text-decoration:underline;text-decoration:underline}}.MnmZ94hS>:first-child,.GDhB96yz{border-radius:9999px;font-size:.75rem;height:2.67em;overflow:hidden;width:2.67em}.anCenz4x{color:#b8b8b8;max-width:10.63rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}@media(max-width:947.98px){.anCenz4x{display:none}}.l5VgdKQD{align-items:center;display:flex;gap:max(.7rem,min(1.04vw,1.25rem))}.MnmZ94hS>:first-child,.MnmZ94hS>:last-child{background:var(--color-primary)}.MnmZ94hS>:last-child{border-radius:9999px;height:max(.28rem,min(.42vw,.5rem));overflow:hidden;width:max(2.81rem,min(4.17vw,5rem))}@media(max-width:947.98px){.MnmZ94hS>:last-child{display:none}}@media(max-width:1229.98px){.dZYxtqrT{display:none!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"placeholder": "MnmZ94hS",
	"user": "giGxK+wI",
	"userName": "anCenz4x",
	"userAvatar": "GDhB96yz",
	"btns": "l5VgdKQD",
	"registration": "dZYxtqrT"
};
module.exports = ___CSS_LOADER_EXPORT___;
