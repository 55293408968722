// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7C5to545{align-items:flex-start;display:flex;flex-wrap:wrap;font-size:max(.75rem,min(.89vw,1.06rem));gap:.5em 1.5em;justify-content:flex-end}@media(max-width:1023.98px){._7C5to545{align-items:flex-end;background:var(--color-primary);border-radius:.53em;flex-direction:column;font-size:1rem;max-width:calc(100% - var(--p-x)*2);opacity:0;padding:1em 1.5em;position:absolute;right:var(--p-x);top:calc(100% + .5rem);transform:translateX(var(--p-x));transition:.2s ease-in-out;transition-property:transform,visibility,opacity;visibility:hidden;z-index:30}._8x7mcUIT{opacity:1;transform:none;visibility:visible}}.myo6e2Kt{color:#fff;font-weight:700}@media(hover:hover){.myo6e2Kt:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.myo6e2Kt:active{-webkit-text-decoration:underline;text-decoration:underline}}.myo6e2Kt.nuxt-link-active{cursor:default;-webkit-text-decoration:underline;text-decoration:underline}@media(min-width:1023.98px){.JoRlB4As{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"nav": "_7C5to545",
	"navOpen": "_8x7mcUIT",
	"link": "myo6e2Kt",
	"btnWrapper": "JoRlB4As"
};
module.exports = ___CSS_LOADER_EXPORT___;
