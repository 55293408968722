const middleware: NuxtMiddleware = ({ store, redirect, app }) => {
  if (store.getters["user/isPayable"]) {
    if (!store.getters["user/isPaid"])
      if (store.getters["user/isPaymentOpen"])
        return redirect(app.localePath("/user/payment"))
      else
        return redirect(app.localePath("/user"))
  } else if (store.getters["user/isApprovable"]) {
    if (!store.getters["user/isApproved"])
      return redirect(app.localePath("/user"))
  }
}
export default middleware
