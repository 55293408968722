// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xiyAkOFA{background:#05063a}.wJJUOsLf{border-top:1px solid hsla(0,0%,100%,.1);-moz-column-gap:max(2.25rem,min(3.33vw,4rem));column-gap:max(2.25rem,min(3.33vw,4rem));flex-wrap:wrap;padding-bottom:max(2.04rem,min(3.02vw,3.63rem));padding-top:max(2.39rem,min(3.54vw,4.25rem));row-gap:max(1.48rem,min(2.19vw,2.63rem))}.wJJUOsLf,.z\\+QKVWiO{display:flex;justify-content:space-between}.jFUqKtd-,.z\\+QKVWiO{flex-direction:column}.jFUqKtd-{align-items:flex-end;display:flex;flex-grow:1}._4OEGZsxf{display:inline-flex;height:max(2.31rem,min(4.84vw,5.81rem));margin-bottom:max(1.79rem,min(2.66vw,3.19rem));width:max(10rem,min(14.32vw,17.19rem))}.gLxn3CLw,.cgZ3O9KL,.earIFQbq{color:#fff;font-size:.75rem;font-weight:400;line-height:1.19;opacity:.5;text-align:right}.cgZ3O9KL{margin-bottom:max(.56rem,min(.83vw,1rem));white-space:nowrap}.earIFQbq{display:flex}@media(hover:hover){.earIFQbq:hover{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.earIFQbq:active{-webkit-text-decoration:underline;text-decoration:underline}}.gLxn3CLw{margin-bottom:3.82em}.re6w0o2\\+,._6Go01u5H{display:flex}@media(hover:hover){.re6w0o2\\+:hover,._6Go01u5H:hover{filter:brightness(80%)}}@media(hover:none){.re6w0o2\\+:active,._6Go01u5H:active{filter:brightness(80%)}}._6Go01u5H{color:#fff;font-size:max(1.23rem,min(1.82vw,2.19rem));margin-bottom:1.2em;white-space:nowrap}.re6w0o2\\+,._6Go01u5H{font-weight:700;line-height:1.3}.re6w0o2\\+{color:#4d898b;font-size:max(.75rem,min(1.15vw,1.38rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"containerBg": "xiyAkOFA",
	"content": "wJJUOsLf",
	"start": "z+QKVWiO",
	"end": "jFUqKtd-",
	"logo": "_4OEGZsxf",
	"address": "gLxn3CLw",
	"copy": "cgZ3O9KL",
	"policy": "earIFQbq",
	"email": "re6w0o2+",
	"phone": "_6Go01u5H"
};
module.exports = ___CSS_LOADER_EXPORT___;
